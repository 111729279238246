import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

import { Wrapper } from '@googlemaps/react-wrapper'

import { HomeIcon } from '@heroicons/react/24/outline'
import { addMonths, format } from 'date-fns'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import useFacilities from '../hooks/useFacilities'

import Button from '../components/Button'
import Autocomplete from '../components/Autocomplete'
import LocationsGrid from '../components/LocationsGrid'
import Container from '../components/Container'
import Seo from '../components/Seo'
import Legend from '../components/Legend'

/**
 * This the main Locations Page Component
 *
 * @param   {obj}  data  data from graphql query
 *
 */

function LocationsPage({ data, location, ...rest }) {
  const {
    allLocation: { nodes: graphLocations },
  } = data

  const [locations, setLocations] = useState(graphLocations)
  const [filterOptions, setFilterOptions] = useState({
    ageGroups: [],
    distance: 0,
  })

  const [filterList, setFilterList] = useState([])
  const [initialized, setInitialized] = useState(false)
  const [reloadAll, setReloadAll] = useState(false)
  const { fetchLocations } = useFacilities()
  const params = {}

  const setParams = coords => {
    const currentDate = new Date()
    const futureDate = addMonths(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), 6)
    params.openOn = format(new Date(futureDate.getFullYear(), futureDate.getMonth(), futureDate.getDate()), 'yyyy-MM-dd')

    if (coords?.lat && coords?.lng) {
      params.coordinates = coords
    }

    if (filterOptions.ageGroups.length !== 0) {
      params.ageGroups = filterOptions.ageGroups
    }

    if (filterOptions.distance) {
      params.distance = filterOptions.distance
    }
  }

  /**
   * Handles getting a fresh set of locations if the user chooses an autocomplete option
   * OR hits the search button
   *
   * @param   {[type]}  coords  [coords description]
   *
   * @return  {[type]}          [return description]
   */
  const handleSubmit = async (coords = {}) => {
    setParams(coords)
    const freshLocations = await fetchLocations(params)

    // Our API Call has no idea what the internal wordpress page is for each location so we need to add it
    // by matching the location name from the API to the location name from Gatsby
    const withLinks = freshLocations?.data?.map(loc => {
      const link = graphLocations.find(item => item.name === loc.name)
      return { ...loc, page: link?.page }
    })
    setLocations(withLinks)
  }

  // Checks local storage for previously saved coordinates and saves state
  useEffect(() => {
    let options = []
    data.allLocation.nodes.map(loco => {
      if (loco.ageGroups.length > options.length) {
        options = []
        loco.ageGroups.map(group => {
          options.push(group.name)
          return options
        })
      }
      return options
    })
    setFilterList(options)

    if (location?.state?.fromHero) return

    if (initialized === false) {
      const currentCoords = JSON.parse(localStorage.getItem('coordinates'))
      if (currentCoords) {
        handleSubmit(currentCoords)
        setInitialized(true)
      }
    }
  }, [])

  return (
    <>
      {/* <Seo title="Child Care Locations" /> */}
      <SeoPlugin post={data} title="Child Care Locations" />
      <section tw="w-full pt-0">
        <div
          tw="sticky -top-20 md:-top-12 lg:-top-3 z-20 pt-10"
          css={[
            css`
              background-image: radial-gradient(
                ellipse at 50% 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0.65) 60%,
                rgba(200, 209, 254, 0) 80%
              );
              @media screen and (max-width: 900px) {
                background-image: linear-gradient(rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0.65) 85%, rgba(255, 255, 255, 0) 100%);
              }
            `,
          ]}
        >
          <h2 tw="text-center my-0 px-4">
            {locations.length} child care locations around the <span tw="whitespace-nowrap">Greater Hamilton Area</span>
          </h2>
          <p tw="text-center pb-0 pt-2 font-bold">Click on a centre below to Request Care and join the waitlist!</p>
          <div tw="pt-4 pb-12 tablet:px-5">
            <Legend />
            <div tw="bg-white rounded-full pl-1.5 py-1 pr-3 shadow-lg flex justify-center mx-auto items-center border border-gray-100 w-full md:w-4/5 lg:w-2/3">
              <HomeIcon tw="h-6 w-6 ml-2 text-purple-500 minWidth[40px]" />
              <Wrapper apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY} libraries={['places']}>
                <Autocomplete
                  tw="w-full text-sm md:text-base pl-1"
                  onUpdate={handleSubmit}
                  loadAll={location?.state?.fromHero}
                  setReloadAll={setReloadAll}
                />
              </Wrapper>
              {/* <Button
                variant="primary"
                size="small"
                tw="w-auto justify-center focus:outline-none md:text-base px-2 md:px-6 shadow-none"
                onClick={handleSubmit}
              >
                <span tw="hidden md:block">Search</span>
                <MagnifyingGlassIcon tw="md:hidden h-6 w-6 m-1" />
              </Button> */}
            </div>
          </div>
        </div>
        <Container tw="pb-10 phone:px-4">
          {locations.length ? <LocationsGrid locations={locations} /> : <p>No locations found</p>}
        </Container>
      </section>
    </>
  )
}

/**
 * Query for data page needs
 */
export const query = graphql`
  query {
    allLocation(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
        phone
        address {
          formattedAddress
          city
          postal
          province
          street
        }
        hoursOfOperation {
          closed
          open
        }
        ageGroups {
          name
          programs {
            name
            closed(formatString: "yyyy-DD-MM")
            open(formatString: "yyyy-DD-MM")
          }
        }
        page {
          uri
        }
        hoursOfOperation {
          closed
          open
        }
      }
    }
  }
`

LocationsPage.defaultProps = {
  data: {
    allLocation: [],
  },
}

LocationsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default LocationsPage
